import React, { useState, useCallback } from 'react';
import { createTheme, ThemeProvider, Container, Grid, Paper, Typography } from '@mui/material';
import { ListeDemandes } from './ListesDemandes';
import InfoVoyageur from './InfoVoyageur';
import Home from './Home';
import InfoFormulaire from './InfoFormulaire';

// Créez un thème personnalisé avec les réglages souhaités pour le container
const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          '@media (min-width: 1200px)': {
            maxWidth: '2400px',
          },
        },
      },
    },
  },
});

const DemandesScreen: React.FC = () => {
  const [selection, setSelection] = useState<{ idVoyageur: number | null; chemin: string | null }>({ idVoyageur: null, chemin: null });
  const [key, setKey] = useState<number>(0); // Clé pour forcer le rechargement du composant ListeDemandes

  const handleSelectionDemande = (idVoyageur: number, chemin: string) => {
    setSelection({ idVoyageur, chemin });
  };

  // Fonction pour rafraîchir la liste des demandes
  const rafraichirDemandes = useCallback(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Home />
        <Container>
          <h2>Demandes</h2>
          <Grid container spacing={3}>
            {/* Liste des demandes au-dessus */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Liste des Demandes</Typography>
                <ListeDemandes onSelectionDemande={handleSelectionDemande} key={key} />
              </Paper>
            </Grid>

            {/* Conteneur pour InfoVoyageur et InfoFormulaire côte à côte */}
            {selection.idVoyageur && selection.chemin && (
              <Grid container item xs={12} spacing={3}>
                {/* Informations du voyageur */}
                <Grid item xs={12} md={6} lg={6}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">Informations du Voyageur</Typography>
                    <InfoVoyageur idVoyageur={selection.idVoyageur} chemin={selection.chemin} onValider={rafraichirDemandes} />
                  </Paper>
                </Grid>

                {/* Informations du formulaire */}
                <Grid item xs={12} md={6} lg={6}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">Informations Formulaire</Typography>
                    <InfoFormulaire chemin={selection.chemin} />
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default DemandesScreen;
