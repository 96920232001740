import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TextField, Button } from '@mui/material';
import { recupereUtilisateurs } from '../Services';
import { Utilisateur } from '../models/Utilisateur';
import Home from './Home';
import * as XLSX from 'xlsx'; // Importation de la bibliothèque xlsx
import { saveAs } from 'file-saver'; // Importation de file-saver pour enregistrer le fichier Excel

const UtilisateursScreen = () => {
  const [utilisateurs, setUtilisateurs] = useState<Utilisateur[]>([]);
  const [filteredUtilisateurs, setFilteredUtilisateurs] = useState<Utilisateur[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchUtilisateurs = async () => {
    try {
      const users = await recupereUtilisateurs();
      setUtilisateurs(users);
      setFilteredUtilisateurs(users);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  };

  useEffect(() => {
    fetchUtilisateurs();
  }, []);

  useEffect(() => {
    // Filtrer les utilisateurs en fonction du terme de recherche
    const filtered = utilisateurs.filter((user) =>
      user.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.telephone.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUtilisateurs(filtered);
  }, [searchTerm, utilisateurs]);

  // Fonction pour exporter les utilisateurs filtrés au format Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredUtilisateurs); // Convertir les données en feuille Excel
    const workbook = XLSX.utils.book_new(); // Créer un nouveau classeur
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Utilisateurs'); // Ajouter la feuille au classeur

    // Générer le fichier Excel en binaire
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Enregistrer le fichier Excel
    saveAs(data, 'utilisateurs.xlsx');
  };

  return (
    <>
      <Home />
      <Typography variant="h6" style={{ margin: '20px 0', marginLeft: '15px' }}>
        Nombre de Clients: {filteredUtilisateurs.length}
      </Typography>
      
      {/* Champ de recherche */}
      <TextField
        label="Rechercher par Nom, Email ou Téléphone"
        variant="outlined"
        style={{ marginBottom: '20px', marginLeft: '20px', width: '50%' }}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Bouton d'exportation */}
      <Button
        variant="contained"
        color="primary"
        onClick={exportToExcel}
        style={{ marginLeft: '20px', marginBottom: '20px' }}
      >
        Exporter en Excel
      </Button>

      <TableContainer component={Paper} style={{ maxHeight: '800px' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Date d'inscription</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUtilisateurs.map((user) => (
              <TableRow key={user.id_utilisateur}>
                <TableCell>{user.id_utilisateur}</TableCell>
                <TableCell>{user.nom}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.telephone}</TableCell>
                <TableCell>{new Date(user.date_inscription).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UtilisateursScreen;
